export const EVENTS_STATUSES_DATA = "EVENTS_STATUSES_DATA";
export const EVENTS_STATUSES_LOADED = "EVENTS_STATUSES_LOADED";
export const EVENTS_STATUSES_ERROR = "EVENTS_STATUSES_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export default function eventsStatusesReducer(state = defaultState, action) {
	switch (action.type) {
		case EVENTS_STATUSES_DATA:
			return { ...state, data: action.payload };
		case EVENTS_STATUSES_LOADED:
			return { ...state, loaded: action.payload };
		case EVENTS_STATUSES_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}