import { CUSTOMERS_DATA, CUSTOMERS_LOADED, CUSTOMERS_ERROR } from "../reducers/customersReducer";

export const getCustomersData = (url, params) => dispatch => {
	
	dispatch({ type: CUSTOMERS_LOADED, payload: false });

	const filtersToURLparams = (filters) => {
		let URLparams = "";
		for (let i = 0, keys = Object.keys(filters); i < keys.length; ++i) {
			if (Array.isArray(filters[keys[i]]))
				for (const arg of filters[keys[i]]) URLparams += `&${keys[i]}=${arg}`;
			else URLparams += `&${keys[i]}=${filters[keys[i]]}`;
		}
		return URLparams;
	}

	fetch(`${url}?${filtersToURLparams(params)}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: CUSTOMERS_DATA, payload: data._data });
				dispatch({ type: CUSTOMERS_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: CUSTOMERS_ERROR, payload: err.message });
		});
}