import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Table, Form, InputGroup, Button } from "react-bootstrap";

import { ConfigContext } from "../context";
import { getPlatformsData } from "../actions/platformsActions";
import { getCustomersData } from "../actions/customersActions";
import { getCustomresFiltersData } from "../actions/customersFiltersActions";
import CustomDatepicker from "../components/UI/CustomDatepicker";
import CustomDropdown from "../components/UI/CustomDropdown";
import Loader from "../components/UI/Loader";
import Filters from "../components/Filters";
import AppliedFilters from "../components/AppliedFilters";
import CustomPagination from "../components/CustomPagination";
import searchIcon from "../assets/images/search.svg";

const initialDateFilter = () => {
	let start = new Date();
	start.setDate(start.getDate() - 30);
	let end = new Date();
	return { [`created_at[]`]: [Math.floor(start.getTime() / 1000), Math.floor(end.getTime() / 1000)] };
}

export default function Customers() {

	const { appConfig } = useContext(ConfigContext);
	const dispatch = useDispatch();
	const userInfoStore = useSelector(state => state.userInfo);
	const platformsStore = useSelector(state => state.platforms);
	const filtersStore = useSelector(state => state.customersFilters);
	const customersStore = useSelector(state => state.customers);
	const savedFilters = JSON.parse(localStorage.getItem("customersAppliedFilters")) || initialDateFilter();
	const [tableLoading, setTableLoading] = useState(true);
	const [columnsToHide, setColumnsToHide] = useState([]);
	const [appliedFilters, setAppliedFilters] = useState(savedFilters);
	const _user_token = localStorage.getItem("userToken");
	const [initialized, setInitialized] = useState(false);
	const [selectedApp, setSelectedApp] = useState(null);

	const updateCustomersList = (params = {}) => {
		dispatch(getCustomersData(`/api/v1/frontend/customers/list`, { _user_token, application_id: userInfoStore.data.selected.application, ...params }));
	}

	const handleColumns = (colName, hide) => {
		if (hide) setColumnsToHide(columnsToHide.filter(x => x !== colName));
		else setColumnsToHide([...columnsToHide, colName]);
	}

	const handleAppliedFilters = (type, key, value) => {
		if (type === "list" || type === "assoc list") {

			if (key in appliedFilters) {
				if (appliedFilters[key].includes(value)) {
					if (appliedFilters[key].length > 1) {
						setAppliedFilters({ ...appliedFilters, [key]: appliedFilters[key].filter(x => x !== value) });
					} else {
						setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
					}
				} else {
					setAppliedFilters({ ...appliedFilters, [key]: [...appliedFilters[key], value] });
				}
			} else {
				setAppliedFilters({ ...appliedFilters, [key]: [value] });
			}

		} else if (type === "boolean" || type === "num_range") {

			if (!value) {
				setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
			} else {
				setAppliedFilters({ ...appliedFilters, [key]: value });
			}

		}
	}

	const handleRemoveFilter = (filterName) => {
		setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([key, _]) => key !== filterName)));
	}

	const handleFilterDate = (date) => {
		setAppliedFilters({ ...appliedFilters, ...date });
	}

	const handleSearch = (e) => {
		setAppliedFilters({ ...appliedFilters, customer_search: e.target.value });
		if (e.target.value.length > 4) updateCustomersList({ ...appliedFilters, customer_search: e.target.value }, "handleSearch");
		else if (e.target.value.length === 0) updateCustomersList({ ...appliedFilters, customer_search: "" }, "handleSearch");
	}

	const handlePage = (num) => {
		setAppliedFilters({ ...appliedFilters, page: num });
	}

	const toggleTestMode = (e) => {
		setAppliedFilters({ ...appliedFilters, test: e.target.checked });
	}

	const handleGoToCustomerCard = (id) => {
		window.open(`/customers/${id}`, "_blank");
	}

	const timestampToDate = (timestamp) => {
		return new Intl.DateTimeFormat("ru-RU", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit"
		}).format(timestamp * 1000);
	}

	useEffect(() => {
		if (!platformsStore.loaded)
			dispatch(getPlatformsData(`/api/v1/frontend/platforms/list`));
		if (!filtersStore.loaded)
			dispatch(getCustomresFiltersData(`/api/v1/frontend/customers/filters`, { _user_token, application_id: userInfoStore.data.selected.application }));
	}, [])

	useEffect(() => {
		if (customersStore.loaded && platformsStore.loaded) setTableLoading(false);
		else setTableLoading(true);
	}, [customersStore.loaded, platformsStore.loaded])

	useEffect(() => {
		const local = localStorage.getItem("customersAppliedFilters");
		const applied = JSON.stringify((appliedFilters));

		if (!initialized || local !== applied || selectedApp !== userInfoStore.data.selected.application) {
			setInitialized(true);
			setSelectedApp(userInfoStore.data.selected.application);
			if (Object.keys(appliedFilters).length > 0 && appliedFilters[`created_at[]`][0] && appliedFilters[`created_at[]`][1]) {
				updateCustomersList(appliedFilters);
				localStorage.setItem("customersAppliedFilters", applied);
			}
		}
	}, [userInfoStore.data.selected.application, appliedFilters])

	return (
		<Container className="px-5">

			{appliedFilters.test &&
				<Row className="bg-primary m-0">
					<Col className="d-flex justify-content-center py-2 px-0">
						<p className="text-white m-0">
							{appConfig.Customers.wording.text_1}
						</p>
					</Col>
				</Row>
			}

			<Row className="d-flex justify-content-between pt-4 m-0">
				<Col xs="auto" className="ps-0 pe-3">
					<Row className="m-0 d-flex align-items-baseline">
						<Col className="ps-0 pe-3">
							<h2 className="SFProDisplay-Bold m-0">
								{appConfig.Customers.wording.text_2}
							</h2>
						</Col>
						<Col className="px-0">
							<p className="m-0">
								{appConfig.Customers.wording.text_3} {customersStore.data ? customersStore.data.total_items : 0} {appConfig.Customers.wording.text_4}
							</p>
						</Col>
					</Row>
				</Col>
				<Col xs="auto" className="ps-0 pe-3">
					<Form.Check
						type="switch"
						label={appConfig.Customers.wording.text_5}
						defaultChecked={appliedFilters.test}
						onChange={toggleTestMode}
					/>
				</Col>
			</Row>

			{filtersStore.loaded &&
				<Row className="d-flex justify-content-between align-items-center pt-2 m-0">
					<Col xs="auto" className="px-0">
						<Filters
							availableFilters={filtersStore.data}
							appliedFilters={appliedFilters}
							handleAppliedFilters={handleAppliedFilters}
							applyFilters={() => updateCustomersList(appliedFilters)}
						/>
					</Col>

					<Col xs="auto" className="px-0">
						<Row className="d-flex align-items-center m-0">

							<Col xs="auto" className="px-2">
								<InputGroup className="border border-2 border-secondary rounded-5">
									<InputGroup.Text className="border-0">
										<img src={searchIcon} className="img-fluid" alt="search" />
									</InputGroup.Text>
									<Form.Control
										defaultValue={appliedFilters.customer_search}
										type="text"
										placeholder={appConfig.Customers.wording.text_6}
										className="border-0 ps-0 pe-5"
										onChange={handleSearch}
									/>
								</InputGroup>
							</Col>

							<Col xs="auto" className="px-2">
								<CustomDatepicker
									withRangeButton={true}
									dateFilterKey="created_at[]"
									appliedDate={appliedFilters[`created_at[]`]}
									changeDate={handleFilterDate}
									dateKey="customers"
									endCorrection={0}
								/>
							</Col>

							<Col xs="auto" className="px-2">
								<Button variant="secondary" className="rounded-5" disabled>
									{appConfig.Customers.wording.text_7}
								</Button>
							</Col>

							<Col xs="auto" className="ps-2 pe-0">
								<CustomDropdown buttonText="Pick columns">
									<div className="white-space-nowrap py-2 px-3">
										{customersStore.loaded && Object.keys(customersStore.data.columns).map((col, i) => (
											<div className="py-1" key={i}>
												<Form.Check
													label={customersStore.data.columns[col]}
													defaultChecked
													onChange={(e) => handleColumns(col, e.target.checked)}
												/>
											</div>
										))}
									</div>
								</CustomDropdown>
							</Col>

						</Row>
					</Col>
				</Row>
			}

			{filtersStore.loaded &&
				< Row className="pt-2 m-0">
					<Col className="px-0">
						<AppliedFilters
							appliedFilters={appliedFilters}
							exceptFilters={["created_at[]", "customer_search", "page", "test"]}
							availableFilters={filtersStore.data}
							handleRemoveFilter={handleRemoveFilter}
						/>
					</Col>
				</Row>
			}

			{tableLoading ? <Loader /> :
				<Container fluid className="px-0">
					<Row className="pt-4 m-0">
						<Col className="px-0">
							<Table responsive>

								<thead>
									<tr>
										{Object.keys(customersStore.data.columns).map((col, i) => !columnsToHide.includes(col) && (
											<th key={i} className={`${col === "ltv" ? "text-end " : ""} opacity-50 fw-normal border-0 custom-cell`}>
												{customersStore.data.columns[col]}
											</th>
										))}
									</tr>
								</thead>

								<tbody className="border-top-0">
									{customersStore.data.records.length > 0
										?
										customersStore.data.records.map((record, i) => (
											<tr key={i}>
												{Object.keys(customersStore.data.columns).map((col, index) => {
													if (!columnsToHide.includes(col)) {
														if (col === "customer_id") return (
															<td key={index} className="cursor-pointer custom-cell" onClick={() => handleGoToCustomerCard(record.id)}>
																{record[col] !== null && `${record[col]}`}
															</td>
														);
														else if (col === "platform") return (
															<td key={index} className="custom-cell">
																{record[col] && platformsStore.data.find(x => x.id === record[col]).name}
															</td>
														);
														else if (col === "created_at" || col === "updated_at") return (
															<td key={index} className="custom-cell">
																{timestampToDate(record[col])}
															</td>
														);
														else return (
															<td key={index} className={`${col === "ltv" ? "text-end " : ""} custom-cell`}>
																{record[col] !== null && `${record[col]}`}
															</td>
														);
													}
													else return null;
												})}
											</tr>
										))
										:
										<tr>
											<td className="text-center custom-cell border-none opacity-50 pt-5" colSpan={Object.keys(customersStore.data.columns).length}>
												{appConfig.Customers.wording.text_8}
											</td>
										</tr>
									}
								</tbody>

							</Table>
						</Col>
					</Row>

					<Row className="m-0">
						<Col className="px-0">
							<CustomPagination
								totalPages={customersStore.data.total_pages}
								currentPage={customersStore.data.current_page}
								handlePage={handlePage}
							/>
						</Col>
					</Row>
				</Container>
			}

		</Container >
	);
}