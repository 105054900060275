import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

export default function SettingsAndroidSdk(props) {

	const initialState = {
		file: {
			id: "",
			name: ""
		},
		file_id: "",
		bundle_identifier: "",
	}

	const [settingsData, setSettingsData] = useState(initialState);
	const _user_token = localStorage.getItem("userToken");
	const [isSaveDisabled, setIsSaveDisabled] = useState(false)

	const uploadFile = async (e) => {
		const formData = new FormData();
		formData.set("file", e.target.files[0]);
		formData.set("_user_token", _user_token);
		formData.set("public", 1);

		setSettingsData({ ...settingsData, file: { ...settingsData.file, name: "Loading..." } });
		setIsSaveDisabled(true);

		try {
			const response = await (await
				fetch(`/api/v1/frontend/files/create`,
					{
						method: "POST",
						body: formData
					}
				)).json();
			if (response._code === 200) {
				handleSettingsData("file", { id: response._data.file_id, name: e.target.files[0].name });
				setIsSaveDisabled(false);
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			props.handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
			setSettingsData({ ...settingsData, file: { ...settingsData.file, name: settingsData.file.name } });
			setIsSaveDisabled(false);
		}
	}

	const handleSettingsData = (key, value) => {
		setSettingsData({ ...settingsData, [key]: value });
	}

	useEffect(() => {
		if (props.settingsData) setSettingsData(props.settingsData);
		else setSettingsData(initialState);
	}, [props.settingsData])

	return (
		<Container fluid className="px-0">
			<Row className="m-0">
				<Col className="px-0">
					<p className="SFProDisplay-Medium m-0">
						{props.config.wording.text_1}
					</p>
					<p className="m-0">
						{props.config.wording.text_2}
					</p>
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col xs={5} className="px-0">
					<Form.Group>
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_3}
						</Form.Label>
						<Form.Control
							type="text"
							value={settingsData.bundle_identifier}
							onChange={(e) => handleSettingsData("bundle_identifier", e.target.value)}
							placeholder={props.config.wording.text_4}
						/>
						<Form.Text muted>
							{props.config.wording.text_5}
						</Form.Text>
					</Form.Group>
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col xs={"auto"} className="ps-0 pe-2">
					<p className="SFProDisplay-Semibold m-0">
						{props.config.wording.text_6}
					</p>
				</Col>
			</Row>

			<Row className="d-flex align-items-center pt-2 pb-4 m-0">
				<Col xs={"auto"} className="ps-0 pe-2">
					<Form.Group>
						<Form.Label className="btn btn-primary rounded-5 px-3 m-0">
							{props.config.wording.text_7}
							<Form.Control
								name="file"
								type="file"
								className="d-none"
								onChange={uploadFile}
								onClick={(e) => e.target.value = null}
								disabled={isSaveDisabled}
							/>
						</Form.Label>
					</Form.Group>
				</Col>
				<Col xs={"auto"} className="ps-0 pe-2">
					<p className="opacity-50 m-0">
						{settingsData?.file?.name ? settingsData.file.name : settingsData.file_id}
					</p>
				</Col>
			</Row>

			<Row className="d-flex align-items-center justify-content-between border-top border-secondary pt-4 m-0">
				<Col xs="auto" className="px-0">
					<Button className="px-4 rounded-5" onClick={() => props.updateSettings("edit_google_details", settingsData)} disabled={isSaveDisabled}>
						{props.settingsData ? props.config.wording.text_8 : props.config.wording.text_9}
					</Button>
				</Col>
				{props.settingsData &&
					<Col xs="auto" className="px-4">
						<Button variant="link" className="text-decoration-none text-dark-red p-0" onClick={() => props.handleModal("delete_google_details")}>
							{props.config.wording.text_10}
						</Button>
					</Col>
				}
			</Row>
		</Container>
	);
}