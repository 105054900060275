import { Container, Row, Col } from "react-bootstrap";

export default function Reports() {

	return (
		<Container className="pt-4 px-5">

			<Row className="d-flex justify-content-between align-items-center m-0">
				<Col xs={"auto"} className="px-0">
					<h2 className="SFProDisplay-Bold m-0">
						Reports
					</h2>
				</Col>
			</Row>

		</Container>
	)
}