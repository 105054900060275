import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Dropdown, Button } from "react-bootstrap";

import { ConfigContext } from "../context";
import { AppContext } from "../context";
import { setUserInfoData } from "../actions/userInfoActions";
import AddNewApp from "./AddNewApp";
import addApp from "../assets/images/add-app.svg";
import allApps from "../assets/images/all-apps.svg";

export default function AppsList() {

	const allAppsObj = {
		icon_url: allApps,
		id: 0,
		name: "All apps"
	}

	const { appConfig } = useContext(ConfigContext);
	const { setAllAppsOverviewEnabled } = useContext(AppContext);
	const dispatch = useDispatch();
	const userInfoStore = useSelector(state => state.userInfo);
	const [showModal, setShowModal] = useState(false);
	const [applicationsList, setApplicationsList] = useState(userInfoStore.data.list.organizations.find(org => org.id === userInfoStore.data.selected.organization).applications);
	const [selectedApp, setSelectedApp] = useState(applicationsList.find(app => app.id === userInfoStore.data.selected.application) || allAppsObj);

	const handleModal = () => {
		setShowModal(!showModal);
	}

	const toggleAllApps = () => {
		setAllAppsOverviewEnabled(true);
		handleChangeApp(0);
	}

	const handleChangeApp = (id) => {
		dispatch(setUserInfoData({
			selected: {
				organization: userInfoStore.data.selected.organization,
				application: id
			}
		}));
	}

	useEffect(() => {
		if (userInfoStore.data.selected.application === 0) setAllAppsOverviewEnabled(true);
		else setAllAppsOverviewEnabled(false);
		setApplicationsList(userInfoStore.data.list.organizations.find(org => org.id === userInfoStore.data.selected.organization).applications);
		setSelectedApp(applicationsList.find(app => app.id === userInfoStore.data.selected.application) || allAppsObj);
	}, [userInfoStore])

	return (selectedApp ?
		<Container fluid className="px-0">
			<Row className="m-0">
				<Col className="px-0">

					<Dropdown>
						<Dropdown.Toggle className="bg-transparent text-black border-0 px-0">
							<img
								src={selectedApp.icon_url}
								className="rounded-5"
								width={28}
								height={28}
								alt="app"
							/>
							<span className="ps-2">
								{selectedApp.name}
							</span>
						</Dropdown.Toggle>

						<Dropdown.Menu className="rounded-5 py-2 px-0">

							<Row className="border-bottom m-0">
								<Col xs={4} className="cursor-pointer px-3">
									<Dropdown.Item className="py-2 px-0" onClick={handleModal}>
										<div className="d-flex align-items-center">
											<Button variant="info" className="rounded-5 p-0 border-0">
												<img
													src={addApp}
													className="rounded-5"
													width={28}
													height={28}
													alt="add app"
												/>
											</Button>
											<span className="ps-2">{appConfig.AppsList.wording.text_2}</span>
										</div>
									</Dropdown.Item>
								</Col>
								<Col xs={4} className="cursor-pointer px-3">
									<Dropdown.Item className="py-2 px-0" onClick={toggleAllApps}>
										<Button variant="info" className="rounded-5 p-0 border-0">
											<img
												src={allApps}
												className="rounded-5"
												width={28}
												height={28}
												alt="all apps"
											/>
										</Button>
										<span className="ps-2">{appConfig.AppsList.wording.text_1}</span>
									</Dropdown.Item>
								</Col>
							</Row>

							<Row className="m-0">
								{applicationsList.map((app, i) => (
									<Col xs={4} className="cursor-pointer pt-2 px-3" key={i}>
										<Dropdown.Item as="li" className="py-2 px-0" onClick={() => handleChangeApp(app.id)}>
											<img src={app.icon_url} className="rounded-5" width={28} height={28} alt="app" />
											<span className="ps-2">{app.name}</span>
										</Dropdown.Item>
									</Col>
								))}
							</Row>

						</Dropdown.Menu>
					</Dropdown>

					<AddNewApp
						showModal={showModal}
						handleModal={handleModal}
						config={appConfig.AppsList.addNewApp}
					/>

				</Col>
			</Row>
		</Container>
		: <></>
	);
}