import { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CustomLink from "./UI/CustomLink";

import { AppContext } from "../context";
import { ConfigContext } from "../context";

export default function Navbar() {

	const { appConfig } = useContext(ConfigContext);
	const { allAppsOverviewEnabled } = useContext(AppContext);

	return (
		<Container className="px-5">

			{allAppsOverviewEnabled
				?
				<Row className="m-0 py-3">
					<Col xs="auto" className="px-0">
						<CustomLink name={appConfig.Navigation.wording.text_1} to="/overview" />
					</Col>
					<Col xs="auto" className="px-0">
						<CustomLink name={appConfig.Navigation.wording.text_6} to="/reports" />
					</Col>
				</Row>
				:
				<Row className="m-0 py-3">
					<Col xs="auto" className="px-0">
						<CustomLink name={appConfig.Navigation.wording.text_1} to="/overview" />
					</Col>
					<Col xs="auto" className="px-0">
						<CustomLink name={appConfig.Navigation.wording.text_2} to="/customers" />
					</Col>
					<Col xs="auto" className="px-0">
						<CustomLink name={appConfig.Navigation.wording.text_3} to="/integrations" />
					</Col>
					<Col xs="auto" className="px-0">
						<CustomLink name={appConfig.Navigation.wording.text_4} to="/events" />
					</Col>
					<Col xs="auto" className="px-0">
						<CustomLink name={appConfig.Navigation.wording.text_5} to="/settings" />
					</Col>
				</Row>
			}

			<Row className="border-bottom m-0 px-0" />
		</Container >
	)
}