import { forwardRef, useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./CustomDatepicker.scss";

import calendarIcon from "../../../assets/images/calendar.svg";
import arrowLeft from "../../../assets/images/arrow-left.svg";
import arrowRight from "../../../assets/images/arrow-right.svg";

export default function CustomDatepicker(props) {

	const endCorrection = props.endCorrection !== undefined && props.endCorrection !== null ? props.endCorrection : 1;

	const [selectedPeriod, setSelectedPeriod] = useState(
		localStorage.getItem(`${props.dateKey}SelectedPeriod`)
			? +localStorage.getItem(`${props.dateKey}SelectedPeriod`)
			: 30);
	const [untillToday, setUntillToday] = useState(localStorage.getItem(`${props.dateKey}UntillToday`) === "true");

	const format = (timestamp) => {
		const raw = new Date(timestamp);
		const zero = raw.setHours(0, 0, 0, 0);
		const result = Math.floor(new Date(zero).getTime() / 1000);
		return result;
	}

	const handlePresetedPeriod = (e) => {
		let start = new Date();
		let end = new Date();
		start.setDate(end.getDate() - e);
		end.setDate(end.getDate() - endCorrection);
		setSelectedPeriod(Math.floor((Math.abs(start - end)) / 86400000));
		setUntillToday(true);
		localStorage.setItem(`${props.dateKey}UntillToday`, true);
		localStorage.setItem(`${props.dateKey}SelectedPeriod`, Math.floor((Math.abs(start - end)) / 86400000));
		props.changeDate({ [props.dateFilterKey]: [format(start), format(end)] });
	}

	const handleDate = ([start, end]) => {
		let today = new Date();
		today.setDate(today.getDate() - endCorrection);
		if (start && !end) {
			props.changeDate({ [props.dateFilterKey]: [format(start), end] });
		} else if (!start && end) {
			props.changeDate({ [props.dateFilterKey]: [start, format(end)] });
		} else if (start && end) {
			setUntillToday(format(today) === format(end));
			localStorage.setItem(`${props.dateKey}UntillToday`, format(today) === format(end));
			props.changeDate({ [props.dateFilterKey]: [format(start), format(end)] });
			setSelectedPeriod(Math.floor((Math.abs(start - end)) / 86400000));
			localStorage.setItem(`${props.dateKey}SelectedPeriod`, Math.floor((Math.abs(start - end)) / 86400000));
		}
	}

	const handlePeriod = (forward) => {
		let start = new Date(props.appliedDate[0] * 1000);
		let end = new Date(props.appliedDate[1] * 1000);
		start.setDate(start.getDate() + 2);
		end.setDate(end.getDate() - endCorrection);
		if (forward) {
			start.setDate(start.getDate() + selectedPeriod);
			end.setDate(end.getDate() + selectedPeriod);
		} else {
			start.setDate(start.getDate() - selectedPeriod);
			end.setDate(end.getDate() - selectedPeriod);
		}
		props.changeDate({ [props.dateFilterKey]: [format(start), format(end)] });
	}

	useEffect(() => {
		let start = new Date();
		start.setDate(start.getDate() - selectedPeriod - endCorrection);
		let end = new Date();
		end.setDate(end.getDate() - endCorrection);
		if (untillToday) {
			props.changeDate({ [props.dateFilterKey]: [format(start), format(end)] });
		} else {
			if (!props.appliedDate) {
				props.changeDate({ [props.dateFilterKey]: [format(start), format(end)] });
			} else {
				setSelectedPeriod(Math.floor((Math.abs(props.appliedDate[0] - props.appliedDate[1])) / 86400));
			}
		}
	}, [])

	const DatepickerContainer = ({ className, children }) => {
		return (
			<Container fluid className={`${className} p-0`}>
				<Row className="m-0">
					<Col className="px-0">
						{children}
					</Col>
				</Row>
				{/* <Row className="m-0">
					<Col className="p-2">
						<Button className="w-100 rounded-5" onClick={props.applyFilters}>
							Apply
						</Button>
					</Col>
				</Row> */}
			</Container>
		);
	};

	const WithoutArrows = forwardRef(({ value, onClick }, ref) =>
		<Button
			variant="secondary"
			className="border-0 rounded-5"
			onClick={onClick}
			ref={ref}
			style={{ whiteSpace: "nowrap" }}
		>
			<img src={calendarIcon} width={19} height={18} alt="calendar" />
			<span className="ps-2">
				{value}
			</span>
		</Button>
	);

	const WithArrows = forwardRef(({ value, onClick }, ref) =>
		<Container fluid className="bg-secondary rounded-5 px-0">
			<Row className="m-0">
				<Col className="d-flex align-items-center px-2">
					<Button variant="secondary" className="rounded-0 px-0" onClick={() => handlePeriod(false)}>
						<img src={arrowLeft} className="img-fluid" alt="arrow" />
					</Button>
				</Col>
				<Col className="d-flex align-items-center ps-3 pe-0">
					<img src={calendarIcon} width={19} height={18} alt="calendar" />
				</Col>
				<Col className="d-flex align-items-center ps-2 pe-3">
					<Button
						variant="secondary"
						className="border-0 rounded-0 p-0"
						onClick={onClick}
						ref={ref}
						style={{ whiteSpace: "nowrap" }}
					>
						{value}
					</Button>
				</Col>
				<Col className="d-flex align-items-center px-2">
					<Button variant="secondary" className="rounded-0 px-0" onClick={() => handlePeriod(true)}>
						<img src={arrowRight} className="img-fluid" alt="arrow" />
					</Button>
				</Col>
			</Row>
		</Container >
	);

	if (props.appliedDate)
		return (props.withRangeButton
			?
			<Container fluid className="px-0">
				<Row className="bg-secondary rounded-5 m-0">
					<Col className="d-flex align-items-center px-0">
						<Form.Group className="border-end">
							<Form.Select
								value={selectedPeriod + 1}
								className="bg-secondary border-0 py-0 rounded-5"
								onChange={e => handlePresetedPeriod(+e.target.value)}
							>
								{!untillToday || (selectedPeriod !== 6
									&& selectedPeriod !== 29
									&& selectedPeriod !== 89)
									?
									<option value={selectedPeriod + 1}>
										{`${untillToday ? "Last" : ""} ${selectedPeriod + 1} days`}
									</option>
									: null
								}
								<option value={7}>
									Last 7 days
								</option>
								<option value={30}>
									Last 30 days
								</option>
								<option value={90}>
									Last 90 days
								</option>
							</Form.Select>
						</Form.Group>
					</Col>
					<Col className="px-0">
						<DatePicker
							selectsRange={true}
							startDate={props.appliedDate[0] * 1000}
							endDate={props.appliedDate[1] * 1000}
							onChange={handleDate}
							customInput={<WithoutArrows />}
							calendarContainer={DatepickerContainer}
							dateFormat="dd MMM yyyy"
						/>
					</Col>
				</Row>
			</Container>
			:
			<DatePicker
				selectsRange={true}
				startDate={props.appliedDate[0] * 1000}
				endDate={props.appliedDate[1] * 1000}
				onChange={handleDate}
				customInput={<WithArrows />}
				calendarContainer={DatepickerContainer}
				dateFormat="dd MMM yyyy"
			/>
		);
	else return <></>;
}