export const EVENTS_DATA = "EVENTS_DATA";
export const EVENTS_LOADED = "EVENTS_LOADED";
export const EVENTS_ERROR = "EVENTS_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export default function eventsReducer(state = defaultState, action) {
	switch (action.type) {
		case EVENTS_DATA:
			return { ...state, data: { ...state.data, ...action.payload } };
		case EVENTS_LOADED:
			return { ...state, loaded: action.payload };
		case EVENTS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}