import { USERINFO_DATA, USERINFO_LOADED, USERINFO_ERROR } from "../reducers/userInfoReducer";

export const getUserInfoData = (url, params) => dispatch => {
	fetch(`${url}?${new URLSearchParams(params).toString()}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				let application = 1;
				let organization = 1;

				if (localStorage.getItem("selectedApp") !== null) application = +localStorage.getItem("selectedApp");
				else if (data._data.organizations.length > 0) application = data._data.organizations[0].applications[0].id;

				if (localStorage.getItem("selectedOrg") !== null) organization = +localStorage.getItem("selectedOrg");
				else if (data._data.organizations.length > 0) organization = data._data.organizations[0].id;

				dispatch({
					type: USERINFO_DATA,
					payload: {
						list: data._data,
						selected: { application, organization }
					}
				});
				dispatch({ type: USERINFO_LOADED, payload: true });
			} else if (data._code >= 400 && data._code < 500) {
				localStorage.clear();
				window.location.replace("/");
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: USERINFO_ERROR, payload: err.message });
		});
}

export const setUserInfoData = payload => dispatch => {
	if (typeof payload.selected.application === "number") localStorage.setItem("selectedApp", payload.selected.application);
	if (typeof payload.selected.organization === "number") localStorage.setItem("selectedOrg", payload.selected.organization);
	dispatch({ type: USERINFO_DATA, payload });
}