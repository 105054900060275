export const ROLES_DATA = "ROLES_DATA";
export const ROLES_LOADED = "ROLES_LOADED";
export const ROLES_ERROR = "ROLES_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export default function rolesReducer(state = defaultState, action) {
	switch (action.type) {
		case ROLES_DATA:
			return { ...state, data: action.payload };
		case ROLES_LOADED:
			return { ...state, loaded: action.payload };
		case ROLES_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}