import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Container, Row, Col, Tabs, Tab, Form } from "react-bootstrap";

import { ConfigContext } from "../context";
import Notification from "../components/UI/Notification";
import amplitudeLogo from "../assets/images/amplitude.svg";
import branchLogo from "../assets/images/branch.svg";
import facebookLogo from "../assets/images/facebook.svg";
import firebaseLogo from "../assets/images/firebase.svg";

export default function Integrations() {

	const { appConfig } = useContext(ConfigContext);
	const userInfoStore = useSelector(state => state.userInfo);
	const [amplitudeEnabled, setAmplitudeEnabled] = useState(false);
	const [branchEnabled, setBranchEnabled] = useState(false);
	const [facebookEnabled, setFacebookEnabled] = useState(false);
	const [firebaseEnabled, setFirebaseEnabled] = useState(false);
	const [notification, setNotification] = useState({ show: false, title: "", text: "", time: "" });
	const _user_token = localStorage.getItem("userToken");

	const toggleIntegration = async (integration, enabled) => {
		try {
			const response = await (
				await fetch(`/api/v1/frontend/integrations/${integration}/${enabled ? "enable" : "disable"}`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							application_id: userInfoStore.data.selected.application
						})
					})
			).json();
			if (response._code === 200) {
				if (integration === "amplitude") setAmplitudeEnabled(enabled);
				else if (integration === "branch") setBranchEnabled(enabled);
				else if (integration === "facebook_pixel") setFacebookEnabled(enabled);
				else if (integration === "firebase") setFirebaseEnabled(enabled);
				setNotification({ show: true, title: "Success", text: response._msg, time: "Now" });
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const getStatus = async (platform) => {
		try {
			const response = await (
				await fetch(`/api/v1/frontend/integrations/${platform}/show?${new URLSearchParams({ _user_token, application_id: userInfoStore.data.selected.application }).toString()}`)
			).json();
			if (response._code === 200) {
				if (platform === "amplitude") setAmplitudeEnabled(response._data !== "{}");
				else if (platform === "branch") setBranchEnabled(response._data !== "{}");
				else if (platform === "facebook_pixel") setFacebookEnabled(response._data !== "{}");
				else if (platform === "firebase") setFirebaseEnabled(response._data !== "{}");
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const handleNotification = (data) => {
		setNotification(data);
	}

	useEffect(() => {
		if (appConfig.Integrations.amplitude.available) getStatus("amplitude");
		getStatus("branch");
		getStatus("facebook_pixel");
		getStatus("firebase");
	}, [userInfoStore.data.selected.application])

	return (
		<Container className="pt-4 px-5">

			<Notification notification={notification} handleNotification={handleNotification} />

			<Row className="m-0">
				<Col className="px-0">
					<h1 className="SFProDisplay-Bold m-0">
						{appConfig.Integrations.wording.text_1}
					</h1>
				</Col>
			</Row>

			<Tabs defaultActiveKey={0} className="pt-4">
				<Tab eventKey={0} title="Analytics">

					<Container fluid className="px-0">

						<Row className="border-bottom border-1 border-secondary pt-4 pb-3 m-0">
							<Col xs={1} className="px-0">
								<p className=" opacity-50 m-0">
									{appConfig.Integrations.wording.text_2}
								</p>
							</Col>
							<Col className="px-3">
								<p className=" opacity-50 m-0">
									{appConfig.Integrations.wording.text_3}
								</p>
							</Col>
						</Row>

						<Row className="d-flex align-items-center border-bottom border-secondary py-4 m-0">
							<Col xs={1} className="px-0">
								<Form.Check
									label={amplitudeEnabled ? "Enabled" : "Disabled"}
									checked={amplitudeEnabled}
									onChange={(e) => toggleIntegration("amplitude", e.target.checked)}
									disabled={!appConfig.Integrations.amplitude.available}
								/>
							</Col>
							<Col className={`${amplitudeEnabled ? "" : "opacity-25 "}px-3`}>
								<Row className="m-0">
									<Col className="d-flex align-items-center px-0">
										<img src={amplitudeLogo} className="img-fluid" alt="amplitude" />
										<span className="ps-3">
											{appConfig.Integrations.wording.text_4}
										</span>
									</Col>
								</Row>
								<Row className="pt-2 m-0">
									<Col className="px-0">
										<p className="m-0">
											{appConfig.Integrations.wording[appConfig.Integrations.amplitude.available ? "text_16" : "text_17"]}
										</p>
									</Col>
								</Row>
							</Col>
							<Col xs="auto" className="px-3">
								{amplitudeEnabled ?
									<Link to="/integrations/amplitude" className="text-decoration-none text-primary m-0">
										{appConfig.Integrations.wording.text_5}
									</Link>
									: <></>
								}
							</Col>
						</Row>

					</Container>
				</Tab>

				<Tab eventKey={1} title="Marketing">

					<Container fluid className="px-0">

						<Row className="border-bottom border-1 border-secondary pt-4 pb-3 m-0">
							<Col xs={1} className="px-0">
								<p className=" opacity-50 m-0">
									State
								</p>
							</Col>
							<Col className="px-3">
								<p className=" opacity-50 m-0">
									{appConfig.Integrations.wording.text_6}
								</p>
							</Col>
						</Row>

						<Row className="d-flex align-items-center border-bottom border-secondary py-4 m-0">
							<Col xs={1} className="px-0">
								<Form.Check
									label={branchEnabled ? "Enabled" : "Disabled"}
									checked={branchEnabled}
									onChange={(e) => toggleIntegration("branch", e.target.checked)}
								/>
							</Col>
							<Col className={`${branchEnabled ? "" : "opacity-25 "}px-3`}>
								<Row className="m-0">
									<Col className="d-flex align-items-center px-0">
										<img src={branchLogo} className="img-fluid" alt="branch" />
										<span className="ps-3">
											{appConfig.Integrations.wording.text_7}
										</span>
									</Col>
								</Row>
								<Row className="pt-2 m-0">
									<Col className="px-0">
										<p className="m-0">
											{appConfig.Integrations.wording.text_8}
										</p>
									</Col>
								</Row>
							</Col>
							<Col xs="auto" className="px-3">
								{branchEnabled ?
									<Link to="/integrations/branch" className="text-decoration-none text-primary m-0">
										{appConfig.Integrations.wording.text_9}
									</Link>
									: <></>
								}
							</Col>
						</Row>

						<Row className="d-flex align-items-center border-bottom border-secondary py-4 m-0">
							<Col xs={1} className="px-0">
								<Form.Check
									label={firebaseEnabled ? "Enabled" : "Disabled"}
									checked={firebaseEnabled}
									onChange={(e) => toggleIntegration("firebase", e.target.checked)}
								/>
							</Col>
							<Col className={`${firebaseEnabled ? "" : "opacity-25 "}px-3`}>
								<Row className="m-0">
									<Col className="d-flex align-items-center px-0">
										<img src={firebaseLogo} className="img-fluid" alt="firebase" />
										<span className="ps-3">
											{appConfig.Integrations.wording.text_10}
										</span>
									</Col>
								</Row>
								<Row className="pt-2 m-0">
									<Col className="px-0">
										<p className="m-0">
											{appConfig.Integrations.wording.text_11}
										</p>
									</Col>
								</Row>
							</Col>
							<Col xs="auto" className="px-3">
								{firebaseEnabled ?
									<Link to="/integrations/firebase" className="text-decoration-none text-primary m-0">
										{appConfig.Integrations.wording.text_12}
									</Link>
									: <></>
								}
							</Col>
						</Row>

						<Row className="d-flex align-items-center py-4 m-0">
							<Col xs={1} className="px-0">
								<Form.Check
									label={facebookEnabled ? "Enabled" : "Disabled"}
									checked={facebookEnabled}
									onChange={(e) => toggleIntegration("facebook_pixel", e.target.checked)}
								/>
							</Col>
							<Col className={`${facebookEnabled ? "" : "opacity-25 "}px-3`}>
								<Row className="m-0">
									<Col className="d-flex align-items-center px-0">
										<img src={facebookLogo} className="img-fluid" alt="facebook_pixel" />
										<span className="ps-3">
											{appConfig.Integrations.wording.text_13}
										</span>
									</Col>
								</Row>
								<Row className="pt-2 m-0">
									<Col className="px-0">
										<p className="m-0">
											{appConfig.Integrations.wording.text_14}
										</p>
									</Col>
								</Row>
							</Col>
							<Col xs="auto" className="px-3">
								{facebookEnabled ?
									<Link to="/integrations/facebook_pixel" className="text-decoration-none text-primary m-0">
										{appConfig.Integrations.wording.text_15}
									</Link>
									: <></>
								}
							</Col>
						</Row>

					</Container>
				</Tab>

				<Tab eventKey={2} title="Webhooks (Coming Soon)" disabled></Tab>
			</Tabs>
		</Container>
	);
}