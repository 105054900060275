import { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

export default function SettingsIosSdk(props) {

	const initialState = {
		appstore_identifier: "",
		shared_secret: "",
		webhook: "",
		support_billing_retry: false,
	}

	const [settingsData, setSettingsData] = useState(null);

	const handleSettingsData = (key, value) => {
		setSettingsData({ ...settingsData, [key]: value });
	}

	useEffect(() => {
		if (props.settingsData) {
			setSettingsData(props.settingsData);
		} else {
			setSettingsData(initialState);
		}
	}, [props.settingsData])

	return (
		settingsData &&
		<Container fluid className="px-0">
			<Row className="m-0">
				<Col className="px-0">
					<p className="SFProDisplay-Medium m-0">
						{props.config.wording.text_1}
					</p>
					<p className="m-0">
						{props.config.wording.text_2}
					</p>
				</Col>
			</Row>

			<Row className="py-4 m-0">
				<Col xs={5} className="px-0">
					<Form.Group>
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_3}
						</Form.Label>
						<Form.Control
							type="text"
							value={settingsData.appstore_identifier}
							onChange={(e) => handleSettingsData("appstore_identifier", e.target.value)}
							placeholder={props.config.wording.text_13}
						/>
						<Form.Text muted>
							{props.config.wording.text_18}
						</Form.Text>
					</Form.Group>

					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_4}
						</Form.Label>
						<Form.Control
							type="text"
							value={settingsData.shared_secret}
							onChange={(e) => handleSettingsData("shared_secret", e.target.value)}
							placeholder={props.config.wording.text_14}
						/>
						<Form.Text muted>
							{props.config.wording.text_5}
						</Form.Text>
					</Form.Group>

					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_6}
						</Form.Label>
						<Form.Control
							as="textarea"
							defaultValue={settingsData.webhook}
							readOnly
						/>
						<Form.Text muted>
							{props.config.wording.text_7}
						</Form.Text>
					</Form.Group>

					<Form.Group className="pt-4">
						<Form.Check
							className="SFProDisplay-Semibold"
							label={props.config.wording.text_11}
							defaultChecked={settingsData.support_billing_retry}
							onChange={(e) => handleSettingsData("support_billing_retry", e.target.checked)}
						/>
						<Form.Text muted>
							{props.config.wording.text_17}
						</Form.Text>
					</Form.Group>

				</Col>
			</Row>

			<Row className="d-flex align-items-center justify-content-between border-top border-secondary pt-4 m-0">
				<Col xs="auto" className="px-0">
					<Button
						className="px-4 rounded-5"
						onClick={() => props.updateSettings("edit_ios_sdk", settingsData)}
					>
						{props.settingsData ? "Update settings" : "Add integration"}
					</Button>
				</Col>
				{props.settingsData &&
					<Col xs="auto" className="px-4">
						<Button variant="link" className="text-decoration-none text-dark-red p-0" onClick={() => props.handleModal("delete_ios_sdk")}>
							{props.config.wording.text_10}
						</Button>
					</Col>
				}
			</Row>
		</Container>
	);
}