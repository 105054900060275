import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col } from "react-bootstrap";

import { ConfigContext } from "../context";
import { AppContext } from "../context";
import { getOverviewData } from "../actions/overviewActions";
import { getOverviewFiltersData } from "../actions/overviewFiltersActions";
import Notification from "../components/UI/Notification";
import CustomDatepicker from "../components/UI/CustomDatepicker";
import Filters from "../components/Filters";
import AppliedFilters from "../components/AppliedFilters";
import Loader from "../components/UI/Loader";
import SetupInfo from "../components/SetupInfo";
import CustomAreaChart from "../components/CustomAreaChart";
import CustomBarChart from "../components/CustomBarChart";
import TableChart from "../components/TableChart";
import CardChart from "../components/CardChart";

const initialDateFilter = () => {
	let start = new Date();
	start.setDate(start.getDate() - 30);
	let end = new Date();
	return { [`day[]`]: [Math.floor(start.getTime() / 1000), Math.floor(end.getTime() / 1000)] };
}

export default function Overview() {

	const { appConfig } = useContext(ConfigContext);
	const { allAppsOverviewEnabled } = useContext(AppContext);
	const dispatch = useDispatch();
	const userInfoStore = useSelector(state => state.userInfo);
	const filtersStore = useSelector(state => state.overviewFilters);
	const overviewStore = useSelector(state => state.overview);
	const [notification, setNotification] = useState({ show: false, title: "", text: "", time: "" });
	const savedFilters = JSON.parse(localStorage.getItem("overviewAppliedFilters")) || initialDateFilter();
	const [appliedFilters, setAppliedFilters] = useState(savedFilters);
	const _user_token = localStorage.getItem("userToken");
	const [initialized, setInitialized] = useState(false);
	const [selectedApp, setSelectedApp] = useState(null);

	const handleAppliedFilters = (type, key, value) => {
		if (type === "list" || type === "assoc list") {

			if (key in appliedFilters) {
				if (appliedFilters[key].includes(value)) {
					if (appliedFilters[key].length > 1) {
						setAppliedFilters({ ...appliedFilters, [key]: appliedFilters[key].filter(x => x !== value) });
					} else {
						setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
					}
				} else {
					setAppliedFilters({ ...appliedFilters, [key]: [...appliedFilters[key], value] });
				}
			} else {
				setAppliedFilters({ ...appliedFilters, [key]: [value] });
			}

		} else if (type === "boolean" || type === "num_range") {

			if (!value) {
				setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([applied, _]) => applied !== key)));
			} else {
				setAppliedFilters({ ...appliedFilters, [key]: value });
			}

		}
	}

	const handleRemoveFilter = (filterName) => {
		setAppliedFilters(Object.fromEntries(Object.entries(appliedFilters).filter(([key, _]) => key !== filterName)));
	}

	const handleFilterDate = (date) => {
		setAppliedFilters({ ...appliedFilters, ...date });
	}

	const updateOverviewList = (params = {}) => {
		dispatch(getOverviewData(`/api/v1/frontend/metrics/list`, { _user_token, application_id: userInfoStore.data.selected.application, ...params }));
	}

	const handleNotification = (data) => {
		setNotification(data);
	}

	useEffect(() => {
		if (!filtersStore.loaded)
			dispatch(getOverviewFiltersData(`/api/v1/frontend/metrics/filters`, { _user_token, application_id: userInfoStore.data.selected.application }));
	}, [])

	useEffect(() => {
		const local = localStorage.getItem("overviewAppliedFilters");
		const applied = JSON.stringify((appliedFilters));

		if (!initialized || local !== applied || selectedApp !== userInfoStore.data.selected.application) {
			setInitialized(true);
			setSelectedApp(userInfoStore.data.selected.application);
			if (Object.keys(appliedFilters).length > 0 && appliedFilters[`day[]`][0] && appliedFilters[`day[]`][1]) {
				updateOverviewList(appliedFilters);
				localStorage.setItem("overviewAppliedFilters", applied);
			}
		}
	}, [userInfoStore.data.selected.application, appliedFilters])

	useEffect(() => {
		if (overviewStore.error)
			handleNotification({ show: true, title: "Error", text: overviewStore.error, time: "Now" })
	}, [overviewStore.error])

	let dataGraphCounter = 0;

	return (
		<Container className="w-100 pb-5 px-5">

			<Notification notification={notification} handleNotification={handleNotification} />

			{!allAppsOverviewEnabled &&
				<Row className="m-0">
					<Col className="px-0">
						<SetupInfo config={appConfig.Overview.setupInfo} />
					</Col>
				</Row>
			}

			{!allAppsOverviewEnabled &&
				<Row className="d-flex justify-content-between m-0 pt-4">
					<Col className="px-0">
						<h2 className="SFProDisplay-Bold m-0">
							{appConfig.Overview.wording.text_1}
						</h2>
					</Col>
				</Row>
			}

			{filtersStore.loaded &&
				<Row className="d-flex justify-content-between align-items-center pt-4 m-0">
					<Col xs="auto" className="px-0">
						<CustomDatepicker
							withRangeButton={true}
							dateFilterKey="day[]"
							appliedDate={appliedFilters[`day[]`]}
							changeDate={handleFilterDate}
							dateKey="overview"
						/>
					</Col>

					<Col xs="auto" className="px-0">
						<Filters
							align="end"
							availableFilters={filtersStore.data}
							appliedFilters={appliedFilters}
							handleAppliedFilters={handleAppliedFilters}
							applyFilters={() => updateOverviewList(appliedFilters)}
						/>
					</Col>
				</Row>
			}

			{filtersStore.loaded &&
				< Row className="pt-3 m-0">
					<Col className="px-0">
						<AppliedFilters
							appliedFilters={appliedFilters}
							exceptFilters={["day[]", "customer_search", "page", "test"]}
							availableFilters={filtersStore.data}
							handleRemoveFilter={handleRemoveFilter}
						/>
					</Col>
				</Row>
			}

			<Row className="pt-3 m-0">
				{!overviewStore.loaded ? <div style={{ position: "relative", marginTop: "10em" }}><Loader /></div> :
					Object.keys(overviewStore.data).length > 0
						? overviewStore.data.map((metrics, i) => {
							if (metrics.data.length > 0) {
								if (metrics.type === 1) return (
									<Col xs={+metrics.size * 4} className="px-0" key={i}>
										<CustomAreaChart
											data={metrics.data}
											timestampKey={"date"}
											dataKey={"positive"}
											name={metrics.name}
											dynamics={metrics.dynamics}
											sum={metrics.sum}
											currency={metrics.unit}
										/>
									</Col>
								)
								else if (+metrics.type === 2) return (
									<Col xs={+metrics.size * 4} className="px-0" key={i}>
										<CustomBarChart
											data={metrics.data}
											timestampKey={"date"}
											dataKey={"positive"}
											negativeKey={"negative" in metrics.data[0] && "negative"}
											name={metrics.name}
											dynamics={metrics.dynamics}
											sum={metrics.sum}
											currency={metrics.unit}
										/>
									</Col>
								)
								else if (+metrics.type === 3) return (
									<Col xs={+metrics.size * 4} className="px-0" key={i}>
										<TableChart
											data={metrics.data}
											rows={metrics.rows}
											name={metrics.name}
											dynamics={metrics.dynamics}
											sum={metrics.sum}
											currency={metrics.unit}
										/>
									</Col>
								)
								else if (+metrics.type === 4) return (
									<Col xs={+metrics.size * 2} className="px-0" key={i}>
										<CardChart
											name={metrics.name}
											dynamics={metrics.dynamics}
											sum={metrics.sum}
											currency={metrics.unit}
										/>
									</Col>
								)
								else return null
							} else {
								if (dataGraphCounter === i) return (
									<Col key={i} className="fs-5 text-center pt-5">{appConfig.Overview.wording.text_2}</Col>
								)
								else {
									dataGraphCounter++;
									return null;
								}
							}
						})
						: <Col className="fs-5 text-center pt-5">{appConfig.Overview.wording.text_2}</Col>}
			</Row>

		</Container >
	);
}