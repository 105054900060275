export const OVERVIEW_DATA = "OVERVIEW_DATA";
export const OVERVIEW_LOADED = "OVERVIEW_LOADED";
export const OVERVIEW_ERROR = "OVERVIEW_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export default function overviewReducer(state = defaultState, action) {
	switch (action.type) {
		case OVERVIEW_DATA:
			return { ...state, data: action.payload };
		case OVERVIEW_LOADED:
			return { ...state, loaded: action.payload };
		case OVERVIEW_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}