import { Container, Row, Col, Table, Badge } from "react-bootstrap";

export default function CustomerCardStripeCustomers(props) {

	const timestampToDate = (timestamp) => {
		return new Intl.DateTimeFormat("ru-RU", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit"
		}).format(timestamp * 1000);
	}

	return (
		<Container fluid className="px-0">

			<Row className="m-0">
				<Col className="px-0">
					<h6 className="SFProDisplay-Bold m-0">
						Customers
					</h6>
				</Col>
			</Row>

			<Row className="pt-3 m-0">
				<Col className="px-0">
					<Table responsive>

						<thead>
							<tr className="border-top border-secondary">
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									ID
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Stripe ID
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Test
								</th>
							</tr>
						</thead>

						<tbody className="border-top-0">
							{props.data.map((rec, i) => (
								<tr className="border-top border-secondary" key={i}>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.id}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.stripe_id}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.test &&
											<Badge pill bg="warning" className="text-dark-yellow fw-normal text-capitalize m-0">
												True
											</Badge>
										}
									</td>
								</tr>
							))}
						</tbody>

					</Table>
				</Col>
			</Row>

		</Container>
	);
}