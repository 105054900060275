import { Container, Row, Col, Badge } from "react-bootstrap";
import { AreaChart, Area, XAxis, Tooltip, ResponsiveContainer, YAxis } from "recharts";
import { format, fromUnixTime } from "date-fns";

export default function CustomAreaChart(props) {

	const floatToInt = (data) => {
		data = data.filter(val => Math.floor(+val.positive) >= 0);
		for (let i = 0; i < data.length; ++i) data[i].positive = Math.floor(data[i].positive);
		return data;
	}

	return (
		<Container fluid className="border-bottom border-end border-secondary p-3">
			<Row className="d-flex justify-content-between m-0">
				<Col className="d-flex align-items-center px-0">
					<span className="text-dark pe-2">
						{props.name}
					</span>
					{props.dynamics &&
						<Badge
							bg={props.dynamics > 0 ? "success" : "danger"}
							className={`${props.dynamics > 0 ? "text-dark-green" : "text-dark-red"} SFProDisplay-Medium fw-normal`}>
							{props.dynamics > 0 && "+"}{props.dynamics} %
						</Badge>
					}
				</Col>
			</Row>
			<Row className="d-flex justify-content-between m-0">
				<Col className="px-0">
					<p className="SFProDisplay-Medium m-0">{props.sum} {props.currency}</p>
				</Col>
			</Row>
			<Row className="m-0">
				<Col className="border-bottom border-2 w-100 px-0" style={{ height: 100 }}>
					<ResponsiveContainer width="100%" height="100%">
						<AreaChart data={floatToInt(props.data)}>
							<Tooltip
								labelFormatter={(label) => format(fromUnixTime(label), "MMM dd")}
								formatter={(value) => [value, `${props.currency}`]}
							/>
							<defs>
								<linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
									<stop offset="0" stopColor="#CFE7FC" stopOpacity={1} />
									<stop offset="100%" stopColor="#FFFFFF" stopOpacity={1} />
								</linearGradient>
							</defs>
							<XAxis
								type="number"
								scale="time"
								ticks={props.data.map(a => a[props.timestampKey])}
								tickFormatter={(tick) => format(fromUnixTime(tick), "MMM dd")}
								domain={[props.data[0][props.timestampKey], props.data[props.data.length - 1][props.timestampKey]]}
								dataKey={props.timestampKey}
								hide
							/>
							<YAxis hide />
							<Area strokeWidth={4} dataKey={props.dataKey} stroke="#2C71C3" fill="url(#colorUv)" />
						</AreaChart>
					</ResponsiveContainer>
				</Col>
			</Row>
			<Row className="d-flex justify-content-between pt-2 m-0">
				<Col className="px-0">
					<p className="text-dark fs-6 m-0">{format(fromUnixTime(props.data[props.data.length - 1][props.timestampKey]), "MMM dd")}</p>
				</Col>
				<Col className="text-end px-0">
					<p className="text-dark fs-6 m-0">{format(fromUnixTime(props.data[0][props.timestampKey]), "MMM dd")}</p>
				</Col>
			</Row>
		</Container >
	);
}