import { Container, Row, Col, Table, Badge } from "react-bootstrap";

export default function CustomerCardPaypalRenewal(props) {

	const timestampToDate = (timestamp) => {
		return new Intl.DateTimeFormat("ru-RU", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit"
		}).format(timestamp * 1000);
	}

	return (
		<Container fluid className="px-0">

			<Row className="m-0">
				<Col className="px-0">
					<h6 className="SFProDisplay-Bold m-0">
						Renewal Info
					</h6>
				</Col>
			</Row>

			<Row className="pt-3 m-0">
				<Col className="px-0">
					<Table responsive>

						<thead>
							<tr className="border-top border-secondary">
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									ID
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Paypal agreement ID
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Amount
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Currency
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Trial
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Trial days
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Period
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Renewing
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Created at
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Test
								</th>
							</tr>
						</thead>

						<tbody className="border-top-0">
							{props.data.map((rec, i) => (
								<tr className="border-top border-secondary" key={i}>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.id}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.paypal_agreement_identifier}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.amount}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.currency}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{`${rec.has_trial}`}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.trial_days}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.period}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.is_renewing &&
											<Badge pill bg="success" className="text-dark-green fw-normal text-capitalize m-0">
												True
											</Badge>
										}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.created_at && timestampToDate(rec.created_at)}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.test &&
											<Badge pill bg="warning" className="text-dark-yellow fw-normal text-capitalize m-0">
												True
											</Badge>
										}
									</td>
								</tr>
							))}
						</tbody>

					</Table>
				</Col>
			</Row>

		</Container>
	);
}