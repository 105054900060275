import { useSelector } from "react-redux";
import { Accordion } from "react-bootstrap";

export default function CustomerCardSidebar(props) {

	const platformsStore = useSelector(state => state.platforms);

	const timestampToDate = (timestamp) => {
		return new Intl.DateTimeFormat("ru-RU", {
			year: "numeric",
			month: "2-digit",
			day: "2-digit",
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit"
		}).format(timestamp * 1000);
	}

	return (
		<Accordion defaultActiveKey={["details", "installs"]} alwaysOpen>
			<Accordion.Item
				className="border-0"
				eventKey="details"
			>
				<Accordion.Button className="custom-accordion border-bottom border-1 border-secondary">
					<h5 className="SFProDisplay-Bold ps-2 m-0">
						Details
					</h5>
				</Accordion.Button>
				<Accordion.Body className="py-3 px-0">
					<div className="py-2">
						<p className="SFProDisplay-Semibold m-0">
							Platform
						</p>
						<p className="text-truncate opacity-50 m-0">
							{props.data.platform ? platformsStore.data.find(x => x.id === props.data.platform).name : "Not set"}
						</p>
					</div>

					<div className="py-2">
						<p className="SFProDisplay-Semibold m-0">
							Internal ID
						</p>
						<p className="text-truncate opacity-50 m-0">
							{props.data.internal_user_id ? props.data.internal_user_id : "Not set"}
						</p>
					</div>

					<div className="py-2">
						<p className="SFProDisplay-Semibold m-0">
							Country
						</p>
						<p className="text-truncate opacity-50 m-0">
							{props.data.store_country ? props.data.store_country : "Not set"}
						</p>
					</div>

					<div className="py-2">
						<p className="SFProDisplay-Semibold m-0">
							Currency
						</p>
						<p className="text-truncate opacity-50 m-0">
							{props.data.currency ? props.data.currency : "Not set"}
						</p>
					</div>

					<div className="py-2">
						<p className="SFProDisplay-Semibold m-0">
							Locale
						</p>
						<p className="text-truncate opacity-50 m-0">
							{props.data.locale ? props.data.locale : "Not set"}
						</p>
					</div>

					<div className="py-2">
						<p className="SFProDisplay-Semibold m-0">
							Channel
						</p>
						<p className="text-truncate opacity-50 m-0">
							{props.data.attribution_channel ? props.data.attribution_channel : "Not set"}
						</p>
					</div>

					<div className="py-2">
						<p className="SFProDisplay-Semibold m-0">
							Campain
						</p>
						<p className="text-truncate opacity-50 m-0">
							{props.data.attribution_campaign ? props.data.attribution_campaign : "Not set"}
						</p>
					</div>

					<div className="py-2">
						<p className="SFProDisplay-Semibold m-0">
							Adgroup
						</p>
						<p className="text-truncate opacity-50 m-0">
							{props.data.attribution_adgroup ? props.data.attribution_adgroup : "Not set"}
						</p>
					</div>

					<div className="py-2">
						<p className="SFProDisplay-Semibold m-0">
							Feature
						</p>
						<p className="text-truncate opacity-50 m-0">
							{props.data.attribution_feature ? props.data.attribution_feature : "Not set"}
						</p>
					</div>
				</Accordion.Body>
			</Accordion.Item>

			{props.data.app_installs && props.data.app_installs.length > 0
				?
				<Accordion.Item
					className="border-0"
					eventKey="installs"
				>
					<Accordion.Button className="custom-accordion border-bottom border-1 border-secondary">
						<h5 className="SFProDisplay-Bold ps-2 m-0">
							Install records
						</h5>
					</Accordion.Button>
					<Accordion.Body className="py-3 px-0">
						{props.data.app_installs.map((install, i) => (
							<div className="border-bottom border-secondary" key={i}>
								<div className="py-2">
									<p className="SFProDisplay-Semibold m-0">
										Created at
									</p>
									<p className="text-truncate opacity-50 m-0">
										{install.created_at ? timestampToDate(install.created_at) : "Not set"}
									</p>
								</div>

								<div className="py-2">
									<p className="SFProDisplay-Semibold m-0">
										Anonymous ID
									</p>
									<p className="text-truncate opacity-50 m-0">
										{install.anonymous_id ? install.anonymous_id : "Not set"}
									</p>
								</div>

								<div className="py-2">
									<p className="SFProDisplay-Semibold m-0">
										Channel
									</p>
									<p className="text-truncate opacity-50 m-0">
										{install.attribution_channel ? install.attribution_channel : "Not set"}
									</p>
								</div>

								<div className="py-2">
									<p className="SFProDisplay-Semibold m-0">
										Campain
									</p>
									<p className="text-truncate opacity-50 m-0">
										{install.attribution_campaign ? install.attribution_campaign : "Not set"}
									</p>
								</div>

								<div className="py-2">
									<p className="SFProDisplay-Semibold m-0">
										Adgroup
									</p>
									<p className="text-truncate opacity-50 m-0">
										{install.attribution_adgroup ? install.attribution_adgroup : "Not set"}
									</p>
								</div>

								<div className="py-2">
									<p className="SFProDisplay-Semibold m-0">
										Feature
									</p>
									<p className="text-truncate opacity-50 m-0">
										{install.attribution_feature ? install.attribution_feature : "Not set"}
									</p>
								</div>
							</div>
						))}
					</Accordion.Body>
				</Accordion.Item>
				:
				<></>
			}
			
		</Accordion>
	);
}