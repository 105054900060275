import { Container, Row, Col, Badge } from "react-bootstrap";

export default function CardChart(props) {
	return (
		<Container fluid className="p-3">
			<Row className="m-0">
				<Col xs="auto" className="px-0">
					<p className="SFProDisplay-Medium text-dark m-0" style={{ paddingTop: "2px" }}>
						{props.name}
					</p>
				</Col>
				{props.dynamics &&
					<Col className="px-2">
						<Badge
							bg={props.dynamics > 0 ? "success" : "danger"}
							className={`${props.dynamics > 0 ? "text-dark-green" : "text-dark-red"} SFProDisplay-Medium fw-normal rounded-10`}>
							{props.dynamics > 0 && "+"}{props.dynamics} %
						</Badge>
					</Col>
				}
			</Row>
			<Row className="m-0">
				<Col className="px-0">
					<p className="SFProDisplay-Medium fs-5 m-0">
						{props.sum} {props.currency}
					</p>
				</Col>
			</Row>
		</Container>
	)
}