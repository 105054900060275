import { CUSTOMERS_FILTERS_DATA, CUSTOMERS_FILTERS_LOADED, CUSTOMERS_FILTERS_ERROR, CUSTOMERS_FILTERS_APPLIED } from "../reducers/customersFiltersReducer";

export const getCustomresFiltersData = (url, params) => (dispatch) => {
	fetch(`${url}?${new URLSearchParams(params).toString()}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: CUSTOMERS_FILTERS_DATA, payload: data._data.filters });
				dispatch({ type: CUSTOMERS_FILTERS_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: CUSTOMERS_FILTERS_ERROR, payload: err.message });
		});
}

export const setCustomersAppliedFilters = (payload) => (dispatch) => {
	dispatch({ type: CUSTOMERS_FILTERS_APPLIED, payload });
}