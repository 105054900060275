import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

import { ConfigContext } from "../context";
import Loader from "../components/UI/Loader";
import Notification from "../components/UI/Notification";

export default function Organizations() {

	const { appConfig } = useContext(ConfigContext);
	const userInfoStore = useSelector(state => state.userInfo);
	const [notification, setNotification] = useState({ show: false, title: "", text: "", time: "" });
	const _user_token = localStorage.getItem("userToken");
	const organization_id = userInfoStore.data.selected.organization;
	const [organizationData, setOrganizationData] = useState(null);
	const [organizationDataLoaded, setOrganizationDataLoaded] = useState(false);

	const getOrganizationData = async () => {
		try {
			const response = await (await
				fetch(`/api/v1/frontend/organization/show?${new URLSearchParams({ _user_token, organization_id }).toString()}`)
			).json();
			if (response._code === 200) {
				setOrganizationData(response._data);
				setOrganizationDataLoaded(true);
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const submitOrganizationData = async () => {
		try {
			const response = await (
				await fetch(`/api/v1/frontend/organization/edit`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							organization_id,
							...organizationData
						})
					})
			).json();
			if (response._code === 200) {
				handleNotification({ show: true, title: "Success", text: response._msg, time: "Now" });
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const handleOrganizationData = (key, value) => {
		setOrganizationData({ ...organizationData, [key]: value })
	}

	const handleNotification = (data) => {
		setNotification(data);
	}

	useEffect(() => {
		getOrganizationData();
	}, [])

	return !organizationDataLoaded ? <Loader /> :
		<Container className="pt-4 px-5">

			<Notification notification={notification} handleNotification={handleNotification} />

			<Row className="m-0">
				<Col className="px-0">
					<h2 className="SFProDisplay-Bold m-0">
						{appConfig.Organizations.wording.text_1}
					</h2>
				</Col>
			</Row>

			<Row className="pt-5 pb-4 m-0">
				<Col xs={5} className="px-0">
					<Form.Group>
						<Form.Label className="SFProDisplay-Semibold">
							{appConfig.Organizations.wording.text_2}
						</Form.Label>
						<Form.Control
							type="text"
							value={organizationData.name || ""}
							onChange={(e) => handleOrganizationData("name", e.target.value)}
							placeholder={appConfig.Organizations.wording.text_3}
						/>
					</Form.Group>
					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{appConfig.Organizations.wording.text_4}
						</Form.Label>
						<Form.Control
							type="text"
							value={organizationData.facebook_token || ""}
							onChange={(e) => handleOrganizationData("facebook_token", e.target.value)}
							placeholder={appConfig.Organizations.wording.text_5}
						/>
						<Form.Text muted>
							{appConfig.Organizations.wording.text_6}
						</Form.Text>
					</Form.Group>
					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{appConfig.Organizations.wording.text_7}
						</Form.Label>
						<Form.Control
							type="text"
							value={organizationData.apple_export_spreadsheet_identifier || ""}
							onChange={(e) => handleOrganizationData("apple_export_spreadsheet_identifier", e.target.value)}
							placeholder={appConfig.Organizations.wording.text_8}
						/>
						<Form.Text muted>
							{appConfig.Organizations.wording.text_9}
						</Form.Text>
					</Form.Group>
					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{appConfig.Organizations.wording.text_10}
						</Form.Label>
						<Form.Control
							type="text"
							value={organizationData.apple_search_ads_export_spreadsheet_identifier || ""}
							onChange={(e) => handleOrganizationData("apple_search_ads_export_spreadsheet_identifier", e.target.value)}
							placeholder={appConfig.Organizations.wording.text_11}
						/>
						<Form.Text muted>
							{appConfig.Organizations.wording.text_12}
						</Form.Text>
					</Form.Group>
					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{appConfig.Organizations.wording.text_13}
						</Form.Label>
						<Form.Control
							type="text"
							value={organizationData.google_export_spreadsheet_identifier || ""}
							onChange={(e) => handleOrganizationData("google_export_spreadsheet_identifier", e.target.value)}
							placeholder={appConfig.Organizations.wording.text_14}
						/>
						<Form.Text muted>
							{appConfig.Organizations.wording.text_15}
						</Form.Text>
					</Form.Group>
					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{appConfig.Organizations.wording.text_16}
						</Form.Label>
						<Form.Control
							type="text"
							value={organizationData.web_export_spreadsheet_identifier || ""}
							onChange={(e) => handleOrganizationData("web_export_spreadsheet_identifier", e.target.value)}
							placeholder={appConfig.Organizations.wording.text_17}
						/>
						<Form.Text muted>
							{appConfig.Organizations.wording.text_18}
						</Form.Text>
					</Form.Group>
				</Col>
			</Row>
			<Row className="d-flex align-items-center justify-content-between border-top border-secondary py-4 m-0">
				<Col xs="auto" className="px-0">
					<Button
						className="px-4 rounded-5"
						onClick={submitOrganizationData}
					>
						{appConfig.Organizations.wording.text_19}
					</Button>
				</Col>
			</Row>

		</Container >

}