import { useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form, Button } from "react-bootstrap";

import { ConfigContext } from "../context";
import { getUserInfoData } from "../actions/userInfoActions";
import Notification from "../components/UI/Notification";

export default function Account() {

	const { appConfig } = useContext(ConfigContext);
	const dispatch = useDispatch();
	const userInfoStore = useSelector(state => state.userInfo);
	const [name, setUserName] = useState(userInfoStore.data.list.name);
	const [email, setEmail] = useState(userInfoStore.data.list.email);
	const [password, setPassword] = useState(null);
	const [confirmation, setConfirmation] = useState(null);
	const [notification, setNotification] = useState({ show: false, title: "", text: "", time: "" });
	const _user_token = localStorage.getItem("userToken");

	const submitUserData = async (data) => {
		try {
			const response = await (
				await fetch(`/api/v1/frontend/users/edit`,
					{
						method: "POST",
						headers: { "Content-Type": "application/json" },
						body: JSON.stringify({
							_user_token,
							user_id: userInfoStore.data.list.id,
							organization_id: userInfoStore.data.selected.organization,
							...data
						})
					})
			).json();
			if (response._code === 200) {
				dispatch(getUserInfoData(`/api/v1/frontend/me/info`, { _user_token: localStorage.getItem("userToken") }));
				handleNotification({ show: true, title: "Success", text: response._msg, time: "Now" });
			} else {
				throw new Error(`(code ${response._code}), ${response._msg}`);
			}
		} catch (err) {
			console.error(err);
			handleNotification({ show: true, title: "Error", text: err.message, time: "Now" });
		}
	}

	const handleSubmit = (newPassword) => {
		if (newPassword) submitUserData({ password, password_confirmation: confirmation });
		else submitUserData({ name, email });
	}

	const handleNotification = (data) => {
		setNotification(data);
	}

	return (
		<Container className="pt-4 pb-5 px-5">

			<Notification notification={notification} handleNotification={handleNotification} />

			<Row className="border-bottom border-secondary pb-4 m-0">
				<Col xs={4} className="px-0">
					<Row className="d-flex align-items-center m-0">
						<Col className="px-0">
							<h2 className="SFProDisplay-Bold m-0">
								{appConfig.Account.wording.text_1}
							</h2>
						</Col>
					</Row>

					<Row className="d-flex align-items-center pt-4 m-0">
						<Col className="px-0">
							<h5 className="SFProDisplay-Bold m-0">
								{appConfig.Account.wording.text_2}
							</h5>
						</Col>
					</Row>

					<Row className="pt-4 m-0">
						<Col className="px-0">
							<Form.Group>
								<Form.Label className="SFProDisplay-Semibold">
									{appConfig.Account.wording.text_3}
								</Form.Label>
								<Form.Control type="text" value={name} onChange={(e) => setUserName(e.target.value)} />
							</Form.Group>
							<Form.Group className="pt-4">
								<Form.Label className="SFProDisplay-Semibold">
									{appConfig.Account.wording.text_4}
								</Form.Label>
								<Form.Control type="text" value={email} onChange={(e) => setEmail(e.target.value)} />
							</Form.Group>
						</Col>
					</Row>

					<Row className="pt-4 m-0">
						<Col xs={"auto"} className="ps-0 pe-2">
							<Button className="px-4 rounded-5" onClick={() => handleSubmit(false)}>
								{appConfig.Account.wording.text_5}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row className="border-bottom border-secondary pb-4 m-0">
				<Col xs={4} className="px-0">

					<Row className="d-flex align-items-center pt-4 m-0">
						<Col className="px-0">
							<h5 className="SFProDisplay-Bold m-0">
								{appConfig.Account.wording.text_6}
							</h5>
						</Col>
					</Row>

					<Row className="pt-4 m-0">
						<Col className="px-0">
							<Form.Group>
								<Form.Label className="SFProDisplay-Semibold">
									{appConfig.Account.wording.text_7}
								</Form.Label>
								<Form.Control type="password" onChange={(e) => setPassword(e.target.value)} />
							</Form.Group>
							<Form.Group className="pt-4">
								<Form.Label className="SFProDisplay-Semibold">
									{appConfig.Account.wording.text_8}
								</Form.Label>
								<Form.Control type="password" onChange={(e) => setConfirmation(e.target.value)} />
							</Form.Group>
						</Col>
					</Row>

					<Row className="pt-4 m-0">
						<Col xs={"auto"} className="ps-0 pe-2">
							<Button className="px-4 rounded-5" onClick={() => handleSubmit(true)}>
								{appConfig.Account.wording.text_9}
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>

			<Row className="border-top border-secondary pt-4 m-0">
				<Col xs={5} className="px-0">
					<Button variant="link" className="text-decoration-none text-dark-red p-0">
						{appConfig.Account.wording.text_10}
					</Button>
				</Col>
			</Row>

		</Container>
	);
}