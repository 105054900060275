import { OVERVIEW_FILTERS_DATA, OVERVIEW_FILTERS_LOADED, OVERVIEW_FILTERS_ERROR, OVERVIEW_FILTERS_APPLIED } from "../reducers/overviewFiltersReducer";

export const getOverviewFiltersData = (url, params) => (dispatch) => {
	fetch(`${url}?${new URLSearchParams(params).toString()}`)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: OVERVIEW_FILTERS_DATA, payload: data._data.filters });
				dispatch({ type: OVERVIEW_FILTERS_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: OVERVIEW_FILTERS_ERROR, payload: err.message });
		});
}

export const setOverviewAppliedFilters = (payload) => (dispatch) => {
	dispatch({ type: OVERVIEW_FILTERS_APPLIED, payload });
}