export const CUSTOMERS_DATA = "CUSTOMERS_DATA";
export const CUSTOMERS_LOADED = "CUSTOMERS_LOADED";
export const CUSTOMERS_ERROR = "CUSTOMERS_ERROR";

const defaultState = {
	data: null,
	loaded: false,
	error: null
};

export default function customersReducer(state = defaultState, action) {
	switch (action.type) {
		case CUSTOMERS_DATA:
			return { ...state, data: { ...state.data, ...action.payload } };
		case CUSTOMERS_LOADED:
			return { ...state, loaded: action.payload };
		case CUSTOMERS_ERROR:
			return { ...state, error: action.payload };
		default:
			return state;
	}
}