export const CUSTOMERS_FILTERS_DATA = "CUSTOMERS_FILTERS_DATA";
export const CUSTOMERS_FILTERS_LOADED = "CUSTOMERS_FILTERS_LOADED";
export const CUSTOMERS_FILTERS_ERROR = "CUSTOMERS_FILTERS_ERROR";
export const CUSTOMERS_FILTERS_APPLIED = "CUSTOMERS_FILTERS_APPLIED";

const defaultState = {
	data: null,
	loaded: false,
	error: null,
	applied: {}
};

export default function customersFiltersReducer(state = defaultState, action) {
	switch (action.type) {
		case CUSTOMERS_FILTERS_DATA:
			return { ...state, data: action.payload };
		case CUSTOMERS_FILTERS_LOADED:
			return { ...state, loaded: action.payload };
		case CUSTOMERS_FILTERS_ERROR:
			return { ...state, error: action.payload };
		case CUSTOMERS_FILTERS_APPLIED:
			return { ...state, applied: { ...state.applied, ...action.payload } };
		default:
			return state;
	}
}