import { Container, Row, Col } from "react-bootstrap";

import AppsList from "./AppsList";
import UserActions from "./UserActions";

export default function Header() {
	return (
		<Container fluid className="bg-light">
			<Container className="px-5">
				<Row className="m-0">
					<Col className="px-0">
						<AppsList />
					</Col>
					<Col className="text-end px-0">
						<UserActions />
					</Col>
				</Row>
			</Container>
		</Container>
	);
}