import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import overviewReducer from "../reducers/overviewReducer";
import overviewFiltersReducer from "../reducers/overviewFiltersReducer";
import userInfoReducer from "../reducers/userInfoReducer";
import appSettingsReducer from "../reducers/appSettingsReducer";
import platformsReducer from "../reducers/platformsReducer";
import customersReducer from "../reducers/customersReducer";
import customersFiltersReducer from "../reducers/customersFiltersReducer";
import integrationStatusesReducer from "../reducers/integrationStatusesReducer";
import eventsReducer from "../reducers/eventsReducer";
import eventsFiltersReducer from "../reducers/eventsFiltersReducer";
import eventsStatusesReducer from "../reducers/eventsStatusesReducer";
import rolesReducer from "../reducers/rolesReducer";
import usersReducer from "../reducers/usersReducer";

const rootReducer = combineReducers({
	overview: overviewReducer,
	overviewFilters: overviewFiltersReducer,
	userInfo: userInfoReducer,
	appSettings: appSettingsReducer,
	platforms: platformsReducer,
	customers: customersReducer,
	customersFilters: customersFiltersReducer,
	integrationStatuses: integrationStatusesReducer,
	events: eventsReducer,
	eventsFilters: eventsFiltersReducer,
	eventsStatuses: eventsStatusesReducer,
	roles: rolesReducer,
	users: usersReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));