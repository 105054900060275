import { Container, Row, Col, Form, Tabs, Tab, Button } from "react-bootstrap";

export default function IntegrationsAmplitude(props) {

	return (
		<Container className="pt-4 pb-5 px-5">

			<Row className="d-flex align-items-center m-0">
				<Col className="px-0">
					<h2 className="SFProDisplay-Bold m-0">
						<span className="text-capitalize">{props.config.wording.text_1}</span> {props.config.wording.text_2}
					</h2>
				</Col>
			</Row>

			<Row className="d-flex align-items-center pt-4 m-0">
				<Col className="px-0">
					<h5 className="SFProDisplay-Bold m-0">
						{props.config.wording.text_3}
					</h5>
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col xs={4} className="px-0">
					<Form.Group>
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_4}
						</Form.Label>
						<Form.Control
							type="text"
							placeholder={props.config.wording.text_5}
							defaultValue={props.integtationData.app_prop}
							key={props.integtationData.app_prop}
							className="mb-2"
							onChange={(e) => props.handleIntegrationsSettings("app_prop", e.target.value)}
						/>
						<Form.Text className="SFProDisplay-Semibold">
							{props.config.wording.text_15}
						</Form.Text>
					</Form.Group>
					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_6}
						</Form.Label>
						<Form.Control
							type="text"
							placeholder={props.config.wording.text_7}
							defaultValue={props.integtationData.api_key}
							key={props.integtationData.api_key}
							className="mb-2"
							onChange={(e) => props.handleIntegrationsSettings("api_key", e.target.value)}
						/>
						<Form.Text className="SFProDisplay-Semibold">
							{props.config.wording.text_8}
						</Form.Text>
					</Form.Group>
					<Form.Group className="pt-4">
						<Form.Label className="SFProDisplay-Semibold">
							{props.config.wording.text_9}
						</Form.Label>
						<Form.Control
							type="text"
							placeholder={props.config.wording.text_10}
							defaultValue={props.integtationData.test_api_key}
							key={props.integtationData.test_api_key}
							className="mb-2"
							onChange={(e) => props.handleIntegrationsSettings("test_api_key", e.target.value)}
						/>
						<Form.Text className="SFProDisplay-Semibold">
							{props.config.wording.text_11}
						</Form.Text>
					</Form.Group>
				</Col>
			</Row>

			<Row className="pt-4 m-0">
				<Col xs="auto" className="px-0">
					<Button className="px-4 rounded-5" onClick={props.handleSubmitIntegrationSettings}>
						{props.config.wording.text_12}
					</Button>
				</Col>
			</Row>

			<Row className="py-4 m-0">
				<Col className="px-0">
					<Tabs defaultActiveKey={0} className="">

						{props.integtationData.events && Object.keys(props.integtationData.events).map((key, i) => (
							<Tab eventKey={i} key={i} title={key} >
								<Row className="m-0">
									<Col xs={4} className="px-0">

										{props.integtationData.events[key].map((event, index) => (
											<Form.Group key={index} className="pt-4">
												<Form.Label className="SFProDisplay-Medium">
													{event.name} {props.config.wording.text_13}
												</Form.Label>
												<Form.Control
													type="text"
													defaultValue={event.value}
													key={event.name}
													onChange={(e) => props.handleEventsSettings(event.id, e.target.value)}
													placeholder={event.name}
												/>
												<Form.Text className="SFProDisplay-Semibold text-lowercase">
													{event.description}
												</Form.Text>
											</Form.Group>
										))}

									</Col>
								</Row>
							</Tab>
						))}

					</Tabs>
				</Col>
			</Row>

			<Row className="border-top border-1 border-secondary pt-4 m-0">
				<Col xs="auto" className="px-0">
					<Button className="px-4 rounded-5" onClick={props.handleSubmitEventsSettings}>
						{props.config.wording.text_14}
					</Button>
				</Col>
			</Row>

		</Container>
	)
}