import { EVENTS_STATUSES_DATA, EVENTS_STATUSES_LOADED, EVENTS_STATUSES_ERROR } from "../reducers/eventsStatusesReducer";

export const getEventsStatusesData = (url) => (dispatch) => {
	fetch(url)
		.then(response => response.json())
		.then(data => {
			if (data._code === 200) {
				dispatch({ type: EVENTS_STATUSES_DATA, payload: data._data.event_statuses });
				dispatch({ type: EVENTS_STATUSES_LOADED, payload: true });
			} else {
				throw new Error(`(code ${data._code}), ${data._msg}`);
			}
		})
		.catch(err => {
			dispatch({ type: EVENTS_STATUSES_ERROR, payload: err.message });
		});
}