import { Container, Row, Col, Table, Badge } from "react-bootstrap";

export default function CustomerCardAppleRenewal(props) {

	return (
		<Container fluid className="px-0">

			<Row className="m-0">
				<Col className="px-0">
					<h6 className="SFProDisplay-Bold m-0">
						Renewal Info
					</h6>
				</Col>
			</Row>

			<Row className="pt-3 m-0">
				<Col className="px-0">
					<Table responsive>

						<thead>
							<tr className="border-top border-secondary">
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									ID
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Product ID
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Original transaction
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Auto renew status
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Expiration intent
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Is in billing retry period
								</th>
								<th className="opacity-50 fw-normal white-space-nowrap ps-0 pe-4">
									Test
								</th>
							</tr>
						</thead>

						<tbody className="border-top-0">
							{props.data.map((rec, i) => (
								<tr className="border-top border-secondary" key={i}>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.id}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.product_identifier}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.original_transaction}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.auto_renew_status ?
											<Badge pill bg="success" className="text-dark-green fw-normal text-capitalize m-0">
												True
											</Badge>
											:
											<Badge pill bg="danger" className="text-dark-red fw-normal text-capitalize m-0">
												False
											</Badge>
										}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.expiration_intent}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.is_in_billing_retry_period &&
											<Badge pill bg="danger" className="text-dark-red fw-normal text-capitalize m-0">
												True
											</Badge>
										}
									</td>
									<td className="white-space-nowrap ps-0 pe-4">
										{rec.test &&
											<Badge pill bg="warning" className="text-dark-yellow fw-normal text-capitalize m-0">
												True
											</Badge>
										}
									</td>
								</tr>
							))}
						</tbody>

					</Table>
				</Col>
			</Row>

		</Container>
	);
}